import { BaseModel } from './baseModel';
import { ComissionadoModel } from './comissionadoModel';

export class AgenciaTurismoModel extends BaseModel {
    nmAgenciaTurismo: string;
    nmFantasiaAgencia: string;
    dsLogradouro: string;
    nuLogradouro: number;
    dsComplementoLogradouro: string;
    nuCEP: string;
    nmBairro: string;
    nmCidadeLogradouro: string;
    sgEstado: string;
    sgPais: string;
    edEmailAgencia: string;
    nmResponsavel: string;
    nuCPFResponsavel: string;
    cdDDITelefone: number;
    nuTelefoneAgencia: string;
    tpAgencia: string;
    nuCPFCNPJ: string;
    dsHorarioIniFuncionamento: string;
    dsHorarioFimFuncionamento: string;
    cdBanco: string;
    nuAgenciaBancaria: string;
    nuDigitoAgenciaBancaria: string;
    nuContaBancaria: string;
    nuDigitoContaBancaria: string;
    tpContaBancaria: string;
    pcTaxaPasseioJaBoleto: number;
    pcTaxaPasseioJaCredito: number;
    pcTaxaPasseioJaDebito: number;
    pcTaxaPasseioJaPix: number;
    pcTaxaPasseioJaOutros: number;
    dlLogotipoAgencia: string;
    dsReferenciaCadastro: string;
    vlScoreCadastro: number;
    cdMunicipioIBGE: string;
    cdEmissaoNF: number;
    comissionado: ComissionadoModel;
    pcTaxaComissao: number;
    dtEncerramentoComissao: string;
    tpPessoaGateway: string;
    cdPix: string;
    tpChavePix: string;
    flTransferirValor: boolean;
    flPixLiberado: boolean;
    dtNascimentoResponsavel: string;
    tpEmpresa: string;
    idAsaas: string;
    dlApiKeyAsaas: string;
    dlWalletIdAsaas: string;
    cdGatewayPagamento: string;
    dsProblemaTransferencia: string;
    dsStatusDocumentacao: string;
    dsStatusGeral: string;
    cdNaturezaJuridica: string;
    vlScoreMedioAgencia: number;
    vlFaturamentoMensal: number;
    flEfetuarSplitAgencia: number;        // default 1
    flEfetuarSplitCartaoAgencia: number;        // default 1

    constructor() {
        super();
        this.comissionado = new ComissionadoModel();
    }

}
